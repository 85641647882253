<!-- the question card template for the default questions -->
<template>
  <v-container>
    <v-hover>
      <template v-slot:default="{ isHovering, props }">
        <v-card 
          class="rounded-lg pa-4 my-0 cursor-pointer text-h6"
          v-bind="props"
          :color="isHovering ? 'light-blue-darken-1' : 'light-blue-lighten-4'"
          @click="$emit('question-clicked', question)"
        >{{ question }}</v-card>
      </template>
    </v-hover>
  </v-container>
</template>
  
<script>
  export default {
    props: {
      question: String
    }
  }
</script>
